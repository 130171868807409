html, #root {
  background-color: #282c34;
}

.row {
  margin-bottom: 10px;
}

a.app-link {
   color: #d3d8e3;
}

a.app-link:hover {
  color: #a2a9b8;
  text-decoration: none;
}

.container-fluid {
  color: white;
  text-align: center;
  padding-top: 40px;
}

.flex-container {
  color: white;
  text-align: center;
  padding-top: 40px;
}

.container {
  color: white;
  text-align: left;
  padding-top: 40px;
}

.home-page-main-title {
  color: white;
}

.transaction-container {
  width: 400px;
  min-width: 300px;
  background-color: #e9edf7;
  border-radius: 10px;
  color: #282c34;
  font-size: calc(10px + 1.0vmin);
}

.transaction-body h3 {
  font-size: calc(10px + 2vmin);
}

.subtitle-container {
  font-size: calc(10px + 0.8vmin);
  color: #596273;
  margin-bottom: 10px;
}

.coffeeshop-body {
  flex: 1;
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .transaction-container {
    width: 300px;
  }
}

@media only screen and (max-width: 576px) {
  .transaction-container {
    width: 80%;
  }
}